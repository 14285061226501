.device-card {
    position: relative;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;
  }
  
  .device-card:hover {
    transform: scale(1.05); /* Scale up the card slightly on hover */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .device-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
  
  .device-name {
    padding: 1rem;
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  @media (max-width: 768px) {
    .device-image {
      height: 150px;
    }
  
    .device-name {
      font-size: 1rem;
    }
  }
  